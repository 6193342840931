<template>
  <div class="shop-list">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增位置停用</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="停用描述" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.stopDescribe || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="停用位置名称" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.stopName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="停用日期范围" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.closeDownStart }}~{{ scope.row.closeDownEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column label="停用时段" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.closeTimeStart }}~{{ scope.row.closeTimeEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建日期" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.createTime || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" >
        <template slot-scope="scope">
          <span :style="{ 'color': stopUseStatusColor[scope.row.status] }">{{ stopUseStatusObj[scope.row.status] || "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)"
            v-has="'resource_stopUser_del'">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="30%"
      custom-class="add-dialog">
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 停用描述 -->
        <el-form-item label="停用描述" label-width="120px" prop="stopDescribe">
          <el-input v-model.trim="params.stopDescribe" autocomplete="off" maxlength="16" placeholder="请填写停用描述"></el-input>
        </el-form-item>
        <!-- 选择位置 -->
        <el-form-item label="选择位置" label-width="120px" prop="roomIds">
          <template v-if="roomList.length > 0">
            <div>
              <el-checkbox :indeterminate="isIndeterminate" v-model="isAll" @change="onIsAll">全选</el-checkbox>
            </div>
            <template v-for="item in roomList">
              <p v-if="item.childRoom.length">{{ item.name }}</p>
              <el-checkbox-group v-model="params.roomIds" @change="onItemRoom" v-if="item.childRoom.length">
                <el-checkbox v-for="i in item.childRoom" :checked="item.checked" :label="i.id" :key="i.id">{{ i.name
                }}</el-checkbox>
              </el-checkbox-group>
              <div style="margin: 15px 0;"></div>
            </template>
          </template>
          <span v-else>无可选位置，请先创建位置</span>
        </el-form-item>
        <!-- 停用起止日期 -->
        <el-form-item label="停业起止日期" label-width="120px" prop="closeDownEnd">
          <el-date-picker v-model="dateToDate" type="daterange" :editable="false" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <!-- 起始时间 -->
        <el-form-item label="起始时间" label-width="120px" prop="closeTimeStart">
          <el-time-select placeholder="起始时间" v-model="params.closeTimeStart" :editable="false" :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '23:30',
            maxTime: params.closeTimeEnd
          }">
          </el-time-select>
        </el-form-item>
        <!-- 结束时间 -->
        <el-form-item label="结束时间" label-width="120px" prop="closeTimeEnd">
          <el-time-select placeholder="结束时间" v-model="params.closeTimeEnd" :editable="false" :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '23:30',
          }">
          </el-time-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getStopUseList,
  getStopRoomList,
  getStopUseEdit,
  getStopUseDel,
} from "@/api/resource/stopuse"
import { rules } from "@/db/rules";
import { ObjToOpt } from "@/utils/utils"
import { stopUseStatusObj, stopUseStatusColor, pickerOptions } from "@/db/objs";
export default {
  data() {
    return {
      rules,
      ObjToOpt,
      stopUseStatusObj,
      stopUseStatusColor,
      pickerOptions,
      // table列表
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        shopId: "",
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框标题
      params: {
        stopDescribe: "", //停用描述
        roomIds: [], //房源信息
        closeDownStart: "",
        closeDownEnd: "",
        closeTimeStart: "",
        closeTimeEnd: "",
      },
      // 选择位置
      roomList: [], // 房源列表
      isAll: false,
      isIndeterminate: false,
      // 停用
      dateToDate: [],
    };
  },
  watch: {
    dateToDate(val) {
      if (val) {
        this.params.closeDownStart = val[0];
        this.params.closeDownEnd = val[1];
      } else {
        this.params.closeDownStart = "";
        this.params.closeDownEnd = "";
      }
    },
  },
  computed: {
    // 房源集合
    rooms() {
      let arr = [];
      for (let item of this.roomList) {
        if (item.childRoom.length > 0) {
          for (let i of item.childRoom) {
            arr.push(i.id)
          }
        }
      }
      return arr
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getStopUseList(); // 【请求】停用列表
    this.getStopRoomList();// 【请求】停用位置列表
  },
  methods: {
    // 【请求】停用列表
    getStopUseList() {
      let data = this.tableParams;
      getStopUseList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】停用位置列表
    getStopRoomList() {
      let data = {
        shopId: this.tableParams.shopId
      }
      getStopRoomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.roomList = res.data;
        }
      });
    },

    // 【请求】新增编辑
    getStopUseEdit() {
      let data = this.params;
      data.roomIds = data.roomIds.toString();
      getStopUseEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.reload();
        }
      })
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getStopUseList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getStopUseList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增位置停用";
      this.isAll = false;
      this.isIndeterminate = false;
      this.dateToDate = [];
      this.params = {
        stopDescribe: "", //停用描述
        roomIds: [], //房源信息
        closeDownStart: "",
        closeDownEnd: "",
        closeTimeStart: "",
        closeTimeEnd: "",
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getStopUseEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = {
        id: row.id
      }
      this.$confirm('你确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getStopUseDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getStopUseList();
          }
        })
      });
    },

    // 【监听】全选
    onIsAll(isAll) {
      this.params.roomIds = isAll ? this.rooms : [];
      this.isIndeterminate = false;
    },

    // 【监听】单选
    onItemRoom(value) {
      this.isAll = value.length === this.rooms.length;
      this.isIndeterminate = value.length > 0 && value.length < this.rooms.length;
    }

  },
};
</script>

<style lang="scss" scoped>
.shop-list {
  padding: .15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}
</style>