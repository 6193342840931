import request from '@/utils/request'
// 房间停用列表
export function getStopUseList(data) {
  return request('post', '/apm/roomStop/list', data)
}

// 停用房间列表
export function getStopRoomList(data) {
  return request('post', '/apm/room/roomListByShop', data)
}

// 新增房间停用
export function getStopUseEdit(data) {
  return request('post', '/apm/roomStop/create', data)
}

// 删除房间停用
export function getStopUseDel(data) {
  return request('post', '/apm/roomStop/delete', data)
}